<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 4px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="appstore"></a-icon>
          <span>资源使用</span>
        </a-space>
      </template>
      <span>资源使用包含资源的 CPU 使用率、内存使用率等。</span>
    </a-page-header>

    <a-tabs v-model="activeTab" :animated="false">
      <a-tab-pane key="list" tab="列表">
        <a-card :bordered="false" style="margin-bottom: 16px">
          <list-layout
            :getTableDataFunc="getTableDataFunc"
            :columnDefaultSelected="columnDefaultSelected"
            :columnsAll="columnsAll"
            :toolsList="['setting', 'reload', 'search', 'leftTool']"
            :refresh="refresh"
            :defaultSelected="tableSelectData"
            @tableSelectData="v => (tableSelectData = v)"
            :table-operation-width="120"
          >
            <!-- 工具栏 -->
            <template #leftTool>
              <create-button
                v-permission="{action: 'base.source_usage_report.create', effect: 'disabled'}"
                @click="$refs.createDrawer.show()"
              >
              </create-button>
            </template>
            <!-- 表格自定义列 -->
            <template #name="{ slotProps }">
              <a-tooltip placement="topLeft" :title="slotProps.text">
                <a
                  v-if="slotProps.record.completed"
                  @click="
                    $router.push({
                      name: 'AssetsUsageReportDetail',
                      params: {
                        id: slotProps.record.id
                      }
                    })
                  "
                >
                  {{ slotProps.text }}
                </a>
                <span v-else>{{ slotProps.text }}</span>
              </a-tooltip>
            </template>
            <template #range="{ slotProps }">
              {{
                `${slotProps.record.datetime_from} ~ ${slotProps.record.datetime_to}`
              }}
            </template>
            <template #completed="{ slotProps }">
              <boolean-tag
                false-color="#1890ff"
                false-name="生成中"
                true-name="已生成"
                :value="slotProps.text"
              ></boolean-tag>
            </template>
            <template #operation="{ slotProps }">
              <delete-button
              v-permission="{action: 'base.source_usage_report.delete', effect: 'disabled'}"
                @confirm="confirm(slotProps.record.id)"
              ></delete-button>
            </template>
          </list-layout>
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="task" tab="定时任务">
        <a-card :bordered="false" style="margin: 0 1px 16px 1px">
          <task-table></task-table>
        </a-card>
      </a-tab-pane>
    </a-tabs>

    <create-drawer ref="createDrawer" @ok="confirm"></create-drawer>
  </div>
</template>

<script>
import { deleteSourceUsageReport, getSourceUsageReportList } from '@/api/report'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import BooleanTag from '@/components/tag/BooleanTag'
import CreateDrawer from './modules/CreateDrawer.vue'
import TaskTable from './modules/TaskTable.vue'
import CreateButton from '@/components/button/CreateButton.vue'

export default {
  name: 'SourceUsageReportList',
  components: {
    BooleanTag,
    CreateDrawer,
    DeleteButton,
    ListLayout,
    TaskTable,
    CreateButton
  },
  data () {
    return {
      activeTab: 'list',
      getTableDataFunc: getSourceUsageReportList,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'range',
          title: '统计周期',
          width: 300,
          scopedSlots: {
            customRender: 'range'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'completed',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'completed'
          },
          filters: [
            {
              text: '已完成',
              value: 'true'
            },
            {
              text: '处理中',
              value: 'false'
            }
          ],
          filterMultiple: false
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: ['name', 'range', 'created_at', 'completed'],
      refresh: false,
      tableSelectData: []
    }
  },
  methods: {
    async confirm (id = '') {
      if (id) {
        await deleteSourceUsageReport(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(
          this.tableSelectData.findIndex(item => item.key === id),
          1
        )
      }
      this.refresh = !this.refresh
    }
  }
}
</script>
