var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"destroy-on-close":true,"title":`定时任务${_vm.isUpdate ? '编辑' : '创建'}`,"visible":_vm.visible,"width":800},on:{"close":_vm.reset}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-divider',{staticStyle:{"margin-top":"0"},attrs:{"orientation":"left"}},[_vm._v("基本设置")]),_c('a-form-model-item',{attrs:{"label":"名称","prop":"name"}},[_c('a-input',{attrs:{"max-length":128,"disabled":_vm.isUpdate},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"资源"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('a-form-model-item',{staticStyle:{"margin-right":"16px","margin-bottom":"0"},attrs:{"prop":"sourceType"}},[_c('a-select',{staticStyle:{"width":"160px"},attrs:{"options":_vm.options.sourceType},model:{value:(_vm.form.sourceType),callback:function ($$v) {_vm.$set(_vm.form, "sourceType", $$v)},expression:"form.sourceType"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"prop":"sourceTarget"}},[_c('a-select',{staticStyle:{"width":"160px"},attrs:{"options":_vm.options.sourceTarget},model:{value:(_vm.form.sourceTarget),callback:function ($$v) {_vm.$set(_vm.form, "sourceTarget", $$v)},expression:"form.sourceTarget"}})],1),(_vm.form.sourceType)?[(_vm.form.sourceTarget === 'group')?_c('a-form-model-item',{staticStyle:{"margin-left":"16px","margin-bottom":"0"},attrs:{"prop":"groups","rules":[
              {
                message: '请选择' + _vm.$t(`source_type.${_vm.form.sourceType}`) + '群组',
                required: true,
                trigger: 'change'
              }
            ]}},[_c('group-select',{staticStyle:{"width":"274px"},attrs:{"source-type":_vm.form.sourceType},model:{value:(_vm.form.groups),callback:function ($$v) {_vm.$set(_vm.form, "groups", $$v)},expression:"form.groups"}})],1):(_vm.form.sourceTarget === 'selected')?_c('a-form-model-item',{staticStyle:{"margin-left":"16px","margin-bottom":"0"},attrs:{"prop":"sources","rules":[
              {
                message: '请选择' + _vm.$t(`source_type.${_vm.form.sourceType}`),
                required: true,
                trigger: 'change'
              }
            ]}},[_c('multi-source-select',{staticStyle:{"width":"274px"},attrs:{"source-type":_vm.form.sourceType},model:{value:(_vm.form.sources),callback:function ($$v) {_vm.$set(_vm.form, "sources", $$v)},expression:"form.sources"}})],1):_vm._e()]:_vm._e()],2)]),_c('a-form-model-item',{attrs:{"label":"任务类型","prop":"cronTypes"}},[_c('a-checkbox-group',{model:{value:(_vm.form.cronTypes),callback:function ($$v) {_vm.$set(_vm.form, "cronTypes", $$v)},expression:"form.cronTypes"}},[_c('a-checkbox',{attrs:{"value":"daily"}},[_vm._v("每日")]),_c('a-checkbox',{attrs:{"value":"weekly"}},[_vm._v("每周")]),_c('a-checkbox',{attrs:{"value":"monthly"}},[_vm._v("每月")])],1)],1),_c('a-form-model-item',{attrs:{"label":"状态","prop":"active"}},[_c('a-switch',{model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}})],1),_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v("阈值设置")]),_c('a-form-model-item',{attrs:{"label":"CPU 使用率","prop":"cpuRange"}},[_c('a-slider',{attrs:{"range":true},model:{value:(_vm.form.cpuRange),callback:function ($$v) {_vm.$set(_vm.form, "cpuRange", $$v)},expression:"form.cpuRange"}})],1),_c('a-form-model-item',{attrs:{"label":"内存使用率","prop":"memoryRange"}},[_c('a-slider',{attrs:{"range":true},model:{value:(_vm.form.memoryRange),callback:function ($$v) {_vm.$set(_vm.form, "memoryRange", $$v)},expression:"form.memoryRange"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"wrapper-col":{ span: 20, offset: 4 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 确定 ")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }