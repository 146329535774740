<template>
  <a-drawer
    :destroy-on-close="true"
    :title="`定时任务${isUpdate ? '编辑' : '创建'}`"
    :visible="visible"
    :width="800"
    @close="reset"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-divider orientation="left" style="margin-top: 0">基本设置</a-divider>
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128" :disabled="isUpdate"></a-input>
      </a-form-model-item>
      <a-form-model-item label="资源">
        <div style="display: flex">
          <a-form-model-item prop="sourceType" style="margin-right: 16px; margin-bottom: 0">
            <a-select
              v-model="form.sourceType"
              :options="options.sourceType"
              style="width: 160px"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item prop="sourceTarget" style="margin-bottom: 0">
            <a-select
              v-model="form.sourceTarget"
              :options="options.sourceTarget"
              style="width: 160px"
            ></a-select>
          </a-form-model-item>
          <template v-if="form.sourceType">
            <a-form-model-item
              v-if="form.sourceTarget === 'group'"
              prop="groups"
              :rules="[
                {
                  message: '请选择' + $t(`source_type.${form.sourceType}`) + '群组',
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px; margin-bottom: 0"
            >
              <group-select
                v-model="form.groups"
                :source-type="form.sourceType"
                style="width: 274px"
              ></group-select>
            </a-form-model-item>
            <a-form-model-item
              v-else-if="form.sourceTarget === 'selected'"
              prop="sources"
              :rules="[
                {
                  message: '请选择' + $t(`source_type.${form.sourceType}`),
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px; margin-bottom: 0"
            >
              <multi-source-select
                v-model="form.sources"
                :source-type="form.sourceType"
                style="width: 274px"
              ></multi-source-select>
            </a-form-model-item>
          </template>
        </div>
      </a-form-model-item>
      <a-form-model-item label="任务类型" prop="cronTypes">
        <a-checkbox-group v-model="form.cronTypes">
          <a-checkbox value="daily">每日</a-checkbox>
          <a-checkbox value="weekly">每周</a-checkbox>
          <a-checkbox value="monthly">每月</a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="active">
        <a-switch v-model="form.active"></a-switch>
      </a-form-model-item>
      <a-divider orientation="left">阈值设置</a-divider>
      <a-form-model-item label="CPU 使用率" prop="cpuRange">
        <a-slider v-model="form.cpuRange" :range="true"></a-slider>
      </a-form-model-item>
      <a-form-model-item label="内存使用率" prop="memoryRange">
        <a-slider v-model="form.memoryRange" :range="true"></a-slider>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="reset">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { createSourceUsageTaskReport, getSourceUsageTaskReport, updateSourceUsageTaskReport } from '@/api/report'
import { sourceTargets, sourceTypes } from '@/utils/const'
import GroupSelect from '@/components/select/GroupSelect'
import MultiSourceSelect from '@/components/select/MultiSourceSelect'

export default {
  name: 'TaskInput',
  components: {
    GroupSelect,
    MultiSourceSelect
  },
  data () {
    return {
      visible: false,
      form: {
        id: '',
        name: '',
        active: false,
        cronTypes: undefined,
        sourceType: undefined,
        sourceTarget: undefined,
        sources: [],
        groups: [],
        cpuRange: [60, 80],
        memoryRange: [60, 80]
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        sourceType: {
          message: '请选择资源类型',
          required: true,
          trigger: 'change'
        },
        sourceTarget: {
          message: '请选择资源范围',
          required: true,
          trigger: 'change'
        },
        cronTypes: [
          {
            message: '请选择任务类型',
            required: true,
            trigger: 'change'
          }
        ]
      },
      options: {
        sourceType: sourceTypes.filter(item => {
          if (item === 'server' || item === 'os') { return item }
        }).map(value => {
          return {
            title: this.$t(`source_type.${value}`),
            value
          }
        }),
        sourceTarget: sourceTargets.map(value => {
          return {
            title: this.$t(`source_target.${value}`),
            value
          }
        })
      },
      loading: false,
      isUpdate: false
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.form.id = id
        this.isUpdate = true
        getSourceUsageTaskReport(id).then(res => {
          const data = res.data
          this.form = {
            id,
            name: data.name,
            active: data.active,
            cronTypes: data.cron_types,
            sourceType: data.source_type,
            sourceTarget: data.source_target,
            cpuRange: data.cpu_range,
            memoryRange: data.memory_range,
            groups: data.groups || [],
            sources: data.sources || []
          }
        }).finally(() => {
          this.visible = true
        })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    reset () {
      this.visible = false
      this.isUpdate = false
      this.form = {
        id: '',
        name: '',
        active: false,
        cronTypes: undefined,
        sourceType: undefined,
        sourceTarget: undefined,
        sources: [],
        groups: [],
        cpuRange: [60, 80],
        memoryRange: [60, 80]
      }
      this.$refs.form.resetFields()
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            source_type: this.form.sourceType,
            source_target: this.form.sourceTarget,
            cpu_range: this.form.cpuRange,
            memory_range: this.form.memoryRange,
            cron_types: this.form.cronTypes,
            active: this.form.active
          }
          if (this.form.sourceTarget === 'group') {
            params.group_ids = this.form.groups.map(group => group.id)
          } else if (this.form.sourceTarget === 'selected') {
            params.source_ids = this.form.sources.map(source => source.id)
          }
          this.loading = true
          if (this.isUpdate) {
            updateSourceUsageTaskReport(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.reset()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            params.name = this.form.name
            createSourceUsageTaskReport(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.reset()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
